<template>
  <b-tabs
    pills
  >
    <product-error-modal
      :errors-data="errorsData"
    ></product-error-modal>

    <!-- Tab: Information -->
    <b-tab active>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Maestro Productos</span>
      </template>
      <product-master-information
        @set-errors-product-info-info-data ="setErrorsData"
      />
    </b-tab>

    <!-- Tab: Categoria -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="DatabaseIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Maestro Categorías</span>
      </template>
      <product-category
      />
    </b-tab>

    <!-- Tab: Nart Sin Clasificar -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="DatabaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="d-none d-sm-inline">Productos Sin Clasificar</span>
      </template>
      <product-classification
        @set-errors-product-classification-info-data ="setErrorsData"
      />
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BTab, BTabs, BCard,
} from 'bootstrap-vue'
import ProductMasterInformation from './ProductMasterInformation.vue'
import ProductClassification from './product-classification/ProductClassification.vue'
import ProductCategory from './ProductCategory/ProductCategory.vue'
import { ref } from 'vue'
import ProductErrorModal from './ProductErrorModal'

export default {
  components: {
    BTab,
    BTabs,
    BCard,

    ProductMasterInformation,
    ProductClassification,
    ProductCategory,
    ProductErrorModal,
  },
  setup() {
    const errorsData = ref([])

    const setErrorsData = (value) => {
      errorsData.value = value
    }

    return {
      errorsData,

      setErrorsData,
    }
  },
}
</script>

<style>

</style>
