<template>
  <b-card
    no-body
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <b-card-body>
        <b-row class="mb-1">
          <b-button id="btn-reset-filters"
            @click="resetFilter"
            variant="primary"
            class="ml-1"
            >
            <b-icon-arrow-counterclockwise/>      
          </b-button>
          <b-popover target="btn-reset-filters" placement="topright" triggers="hover">Limpia los filtros aplicados</b-popover>

          <b-button id="btn-download"
            @click="downloadProductMaster"
            variant="primary"
            class="ml-1"            
            >
            <b-icon-download/>  Descargar
          </b-button>
          <b-popover target="btn-download" placement="topright" triggers="hover">Descarga el Maestro de Productos completo</b-popover>

          <b-button id="btn-save"
            @click="saveData"
            :disabled="isDisabledSaveData"
            variant="primary"
            class="ml-1"
            >
            <b-icon-arrow-down-circle-fill/>  Guardar
          </b-button>
          <b-popover target="btn-save" placement="topright" triggers="hover">Guarda los cambios realizados</b-popover>
        </b-row>
        <b-row style="padding: 13px">
          <v-grid
            ref="refGridProductMaster"
            :source="source" 
            :columns="columns" 
            theme="material" 
            resize="true"
            autoSizeColumn = true
            range="true"
            filter="true"
            :columnTypes="pluginRevo"
            class="grid-container-product-master"
            @beforeeditstart="beforeEditStart"
            @focusout="commitCell"
          ></v-grid>
        </b-row>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody, BPopover,
  BRow, BIconArrowCounterclockwise, BIconDownload, BIconArrowDownCircleFill
} from 'bootstrap-vue'
import { ref, getCurrentInstance, computed } from 'vue'
import VGrid from '@revolist/vue-datagrid';
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,
    BPopover,
    CustomLoading,
    VGrid,
    BIconArrowCounterclockwise,
    BIconDownload,
    BIconArrowDownCircleFill
  },
  setup(props, context) {
    const isAddNewRowSidebarActive = ref(false)
    const source = ref([])
    const isLoading = ref(true)
    const refGridProductMaster = ref(null)
    const columns = ref([])
    const revoRowIndex = ref(null)
    const revoColName = ref(null)
    const modifiedData = ref([])
    const inputErrorsData = ref(null)

    const pluginRevo = { 'numeric': new NumberColumnType('0,0') }

    const vm = getCurrentInstance().proxy

    const clearRowSelection = () => {
      revoRowIndex.value = null
      revoColName.value = null
    }

    const clearRevoValues = () => {
      clearRowSelection()
      modifiedData.value = []
    }

    const resetFilter = () => {
      refGridProductMaster.value.$el.getPlugins().then((plugins) => {
        plugins.forEach((p) => {
          if(p.clearFiltering) {
            p.clearFiltering()
            clearRowSelection()
          }
        })
      })
    }

    const beforeEditStart = (event) => {
      revoRowIndex.value = event.detail.rowIndex
      revoColName.value = event.detail.prop
    }

    const commitCell = async (event) => {
      const originalData = await refGridProductMaster.value.$el.getSource()
      const viewData = await refGridProductMaster.value.$el.getVisibleSource()
      let rowData = viewData[revoRowIndex.value]
      let oldValue = rowData[revoColName.value]
      let newValue = event.target.value
      if (oldValue != newValue) {
        rowData[revoColName.value] = newValue
        modifiedData.value.push(Object.assign({}, rowData))
        refGridProductMaster.value.$el.source = [...originalData]
      }
      clearRowSelection()
    }

    const showErrorDetails = () => {
      context.emit('set-errors-product-info-info-data', inputErrorsData.value.error)
      vm.$bvModal.show('product-card-error-modal')
    }

    const saveData = async () => {
      if(modifiedData.value.length > 0) {
        vm.$swal({
          title: `¿Está seguro de que desea guardar los cambios?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            return axios
              .post(`${process.env.VUE_APP_BASE_URL}/update_product`,{
                "data": modifiedData.value
              })
              .then(response => {
                if (response.status != 200) {
                  throw new Error(response.statusText)
                }
                return response
              })
              .catch(error => {
                if(Array.isArray(error.response.data.error) && error.response.data.error.length > 0) {
                  inputErrorsData.value = error.response.data

                  vm.$swal({
                    title: 'Error!',
                    text: 'Se encontraron observaciones',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    confirmButtonText: 'Ver',
                    buttonsStyling: false,
                    allowOutsideClick: false,
                    willClose: showErrorDetails
                  })
                } else {
                  vm.$swal.showValidationMessage(`Request failed:  ${error}`)
                }
              })
          },
        }).then( async (result) => {
          if (result.value) {
            vm.$swal({
              icon: 'success',
              title: 'Datos guardados!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              willClose: clearRevoValues
            })
          } 
        })
      }
    }

    const downloadProductMaster = () => {
      vm.$swal({
        title: `¿Está seguro de que quiere descargar el archivo Maestro de Productos?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then( async (result) => {
        if (result.value) {
          try {
            const response = await axios
            .post(`${process.env.VUE_APP_BASE_URL}/clone_product`, {},
              {
                responseType: 'blob'
              }
            )
            
            const filename = response.headers.filename
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
          } catch(e) {
            vm.$swal({
              title: 'Error!',
              text: 'No se pudo descargar el archivo de maestro de productos',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        } 
      })
    }
    

    // subscription 
    columns.value = [
      {
        "name": "NART",
        "prop": "Material",
        "size": 170,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "SPGR",
        "prop": "SPGR",
        "size": 120,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "SPGR HISTORICO",
        "prop": "SPGR_historico",
        "size": 200,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "TIPO",
        "prop": "TIPO",
        "size": 150,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "Descripcion",
        "prop": "Descripcion",
        "size": 400,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "BPU",
        "prop": "BPU",
        "size": 130,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "Brand Category",
        "prop": "BrandCategory",
        "size": 200,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "Application Form",
        "prop": "ApplicationForm",
        "size": 200,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "EAN",
        "prop": "EAN",
        "size": 200,
        "autoSize": true,
        "sortable": true,
      },
    ]

    vm.$apollo.addSmartSubscription('getProductMaster', {
      query: gql`
        subscription {
          Maestro_productos {
            Material
            SPGR
            TIPO
            Descripcion
            BPU
            BrandCategory
            ApplicationForm
            EAN
            SPGR_historico
          }
        }
      `,
      result ({data}) {
        isLoading.value = false
        source.value = data.Maestro_productos

        // remove _typename
        for(let obj of source.value) {
          delete obj.__typename
        }
      },
    })

    // computed
    const isDisabledSaveData = computed(() => {
      return modifiedData.value.length <= 0
    })

    //onCreated
    clearRevoValues()

    return {
      isAddNewRowSidebarActive,
      source,
      columns,
      isLoading,
      refGridProductMaster,
      pluginRevo,

      resetFilter,
      beforeEditStart,
      commitCell,
      saveData,
      downloadProductMaster,

      clearRevoValues,
      isDisabledSaveData,
    }
  },
}
</script>

<style lang="scss">
.grid-container-product-master {
  width: 100%;
  height: 70vh;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
