<template>
  <b-card
    no-body
  >
    <product-category-add-row
      :is-add-new-row-category-sidebar-active.sync="isAddNewRowSidebarActive"
      @refetch-data="clearRevoValues"
    />

    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <b-card-body>
        <b-row class="mb-1">
          <b-button id="btn-reset"
            @click="resetFilter"
            variant="primary"
            class="ml-1"
            >
            <b-icon-arrow-counterclockwise/> 
          </b-button>
          <b-popover target="btn-reset" placement="topright" triggers="hover">Limpia los filtros aplicados</b-popover>

          <b-button id="btn-agregar"
          @click="isAddNewRowSidebarActive = true"
          variant="primary"
          class="ml-1"
          >
          <b-icon-plus/>  Agregar registro          
          </b-button>
          <b-popover target="btn-agregar" placement="topright" triggers="hover">Agrega un registro al Maestro de Categorías</b-popover>

          <b-button id="btn-save"
          @click="saveData"
          :disabled="isDisabledSaveData"
          variant="primary"
          class="ml-1"
          >
          <b-icon-arrow-down-circle-fill/>  Guardar  
          </b-button>
          <b-popover target="btn-save" placement="topright" triggers="hover">Limpia los filtros aplicados</b-popover>          

          <b-button 
          @click="deleteRow"
          :disabled="isDisabledDeleteRow"
          variant="primary"
          class="ml-1"
          >
          <b-icon-trash/>  Borrar
          </b-button>
          <b-popover target="btn-delete" placement="topright" triggers="hover">Borra los registros seleccionados</b-popover>
          
        </b-row>
        <b-row style="padding: 13px">
          <div v-if="showSelectedRows">
            <strong># Registros Seleccionados: {{Object.keys(selectedRows).length}} </strong>
          </div>
          <v-grid
            ref="refGridProductCategory"
            :source="source" 
            :columns="getColumns" 
            theme="material" 
            resize="true"
            autoSizeColumn = true
            range="true"
            filter="true"
            class="grid-container-product-category"
            @beforeeditstart="beforeEditStart"
            @focusout="commitCell"
          ></v-grid>
        </b-row>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody, BPopover,
  BRow,BIconArrowDownCircleFill,BIconArrowCounterclockwise, BIconTrash, BIconPlus
} from 'bootstrap-vue'
import { ref, getCurrentInstance, computed, watch } from 'vue'
import VGrid from '@revolist/vue-datagrid';
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import gql from 'graphql-tag'
import ProductCategoryAddRow from './ProductCategoryAddRow.vue'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,
    BPopover,
    CustomLoading,
    VGrid,
    ProductCategoryAddRow,
    BIconArrowDownCircleFill,BIconArrowCounterclockwise, BIconTrash,BIconPlus
  },
  setup() {
    const isAddNewRowSidebarActive = ref(false)
    const source = ref([])
    const isLoading = ref(true)
    const refGridProductCategory = ref(null)
    const columns = ref([])
    const revoRowIndex = ref(null)
    const revoColName = ref(null)
    const modifiedData = ref([])

    const selectedRows = ref({})
    const allSelectedStatus = ref(0)

    const vm = getCurrentInstance().proxy

    columns.value = [
      {
        "name": "Category",
        "prop": "category",
        "size": 250,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
      },
      {
        "name": "Descripcion",
        "prop": "name",
        "size": 300,
        "autoSize": true,
        "sortable": true,
      }
    ]

    const clearRowSelection = () => {
      revoRowIndex.value = null
      revoColName.value = null
    }

    const clearRevoValues = () => {
      clearRowSelection()
      modifiedData.value = []
      selectedRows.value = {}
    }

    const resetFilter = () => {
      refGridProductCategory.value.$el.getPlugins().then((plugins) => {
        plugins.forEach((p) => {
          if(p.clearFiltering) {
            p.clearFiltering()
            clearRowSelection()
          }
        })
      })
    }

    const beforeEditStart = (event) => {
      revoRowIndex.value = event.detail.rowIndex
      revoColName.value = event.detail.prop
    }

    const commitCell = async (event) => {
      const originalData = await refGridProductCategory.value.$el.getSource()
      const viewData = await refGridProductCategory.value.$el.getVisibleSource()
      let rowData = viewData[revoRowIndex.value]
      let oldValue = rowData[revoColName.value]
      let newValue = event.target.value
      if (oldValue != newValue) {
        rowData[revoColName.value] = newValue
        modifiedData.value.push(Object.assign({}, rowData))
        refGridProductCategory.value.$el.source = [...originalData]
      }
      clearRowSelection()
    }

    const saveData = async () => {
      if(modifiedData.value.length > 0) {
        vm.$swal({
          title: `¿Está seguro de que quiere guardar los cambios?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            return axios
              .post(`${process.env.VUE_APP_BASE_URL}/upsert_category`,{
                "data": modifiedData.value
              })
              .then(response => {
                if (response.status != 200) {
                  throw new Error(response.statusText)
                }
                return response
              })
              .catch(error => {
                vm.$swal.showValidationMessage(`Request failed:  ${error}`)
              })
          },
        }).then( async (result) => {
          if (result.value) {
            vm.$swal({
              icon: 'success',
              title: 'Datos guardados!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              willClose: clearRevoValues
            })
          } 
        })
      }
    }

    const deleteRow = async () => {
      let selectedRowsIds= Object.keys(selectedRows.value)
      if(selectedRowsIds.length > 0) {
        vm.$swal({
          title: `¿Está seguro de que quiere eliminar los registros seleccionados?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            return axios
              .post(`${process.env.VUE_APP_BASE_URL}/delete_category`,{
                "data": selectedRowsIds
              })
              .then(response => {
                if (response.status != 200) {
                  throw new Error(response.statusText)
                }
                return response
              })
              .catch(error => {
                vm.$swal.showValidationMessage(`Request failed:  ${error}`)
              })
          },
        }).then( async (result) => {
          if (result.value) {
            vm.$swal({
              icon: 'success',
              title: 'Datos eliminados!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              willClose: clearRevoValues
            })
          } 
        })
      }
    }
    
    // select all checkbox click
    const selectAll = (ckecked = false) => {
      source.value.forEach((r) => updateSelectedRow(r, ckecked));
      selectedRows.value = { ...selectedRows.value };
      source.value = [...source.value];
    }

    // regular row checkbox click
    const selectSingle = (row, checked) => {
      updateSelectedRow(row, checked);
      selectedRows.value = { ...selectedRows.value };
    }

    // internal update method
    const updateSelectedRow = (row, checked) => {
      row.selectedRows = checked;
      if (checked) {
        selectedRows.value[row.id] = true;
      } else {
        delete selectedRows.value[row.id];
      }
    }

    // we need it to trigger vNode update, in other case DOM update will not be triggered for this node
    const doChange = (vNode, isChecked) => {
      if (vNode) {
        vNode.$attrs$.checked = isChecked;
      }
    }

    // subscription 
    vm.$apollo.addSmartSubscription('getMaestroCategorias', {
      query: gql`
        subscription {
          Maestro_categorias(order_by: {category: asc}) {
            id
            name
            category
          }
        }
      `,
      result ({data}) {
        isLoading.value = false
        source.value = data.Maestro_categorias

        // remove _typename
        for(let obj of source.value) {
          delete obj.__typename
        }
      },
    })

    // computed
    const isDisabledSaveData = computed(() => {
      return modifiedData.value.length <= 0
    })

    const isDisabledDeleteRow = computed(() => {
      return Object.keys(selectedRows.value).length <= 0
    })

    const showSelectedRows = computed(() => {
      return Object.keys(selectedRows.value).length > 0
    })

    const getColumns = computed(() => {
      // if select all status changed redraw columns
      const status = allSelectedStatus.value;
      const columnTemplate = (h) => {
        let inputVNode = h("input", {
          type: "checkbox",
          indeterminate: status === 1,
          checked: status > 1 || undefined,
          onChange: (e) => {
            selectAll(e.target.checked);
            doChange(inputVNode, e.target.checked);
          },
        });
        return inputVNode;
      };

      const cellTemplate = (h, { model, prop }) => {
        let inputVNode = h("input", {
          type: "checkbox",
          checked: model.selectedRows || undefined,
          onChange: (e) => {
            selectSingle(model, e.target.checked);
            doChange(inputVNode, e.target.checked);
          },
        });
        return inputVNode;
      };

      return [
        {
          prop: "id",
          columnTemplate,
          cellTemplate,
          "readonly": true,
          "size": 50,
          "filter": false
        },
        {
          "name": "Category",
          "prop": "category",
          "size": 250,
          "autoSize": true,
          "sortable": true,
          "readonly": true,
        },
        {
          "name": "Descripcion",
          "prop": "name",
          "size": 300,
          "autoSize": true,
          "sortable": true,
        }
      ];
    })

    const isAllSelected = computed(() => {
      const selectedRowLength = Object.keys(selectedRows.value).length;
      if (selectedRowLength === source.value.length) {
        return 2;
      }
      if (selectedRowLength) {
        return 1;
      }
      return 0;
    })

    // watch
    watch(isAllSelected, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        allSelectedStatus.value = newValue
      }
    })

    //onCreated
    clearRevoValues()

    return {
      isAddNewRowSidebarActive,
      source,
      isLoading,
      refGridProductCategory,
      selectedRows,
      allSelectedStatus,

      resetFilter,
      beforeEditStart,
      commitCell,
      saveData,
      deleteRow,

      clearRevoValues,
      isDisabledSaveData,
      isDisabledDeleteRow,
      showSelectedRows,
      getColumns,
    }
  },
}
</script>

<style lang="scss">
.grid-container-product-category {
  width: 100%;
  height: 70vh;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
