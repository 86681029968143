<template>
  <b-modal
    id="product-card-error-modal"
    ref="refErrorModal"
    centered
    no-close-on-backdrop
    title="Detalle Errores"
    cancel-variant="outline-secondary"
    ok-only
  >
    <b-card-text>
      <ul>
        <li :key="idx" v-for="(error, idx) in errorsDataModal">
          <strong>Nart: </strong> {{ error.material }}
          <br>
          <strong>Errores: </strong>
          <ul>
            <li :key="i" v-for="(e, i) in error.errores">
              {{e}}
            </li>
          </ul>
        </li>
      </ul>
    </b-card-text>
  </b-modal>
</template>

<script>
import { BButton, BModal, BCardText,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, toRefs, watch } from 'vue'

export default {
  components: {
    BButton,
    BModal,
    BCardText,

  },
  directives: {
    Ripple,
  },
  props: {
    errorsData: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    console.log('ProductErrorDataModal  props: ', props)
    const { errorsData } = toRefs(props)
    const refErrorModal = ref(null)
    const errorsDataModal = ref([])

    // load data to vue-select
    watch(errorsData, (newValue, oldValue) => {
      errorsDataModal.value = newValue
      console.log('errosdatamodal', errorsDataModal)
      console.log('errosdata', errorsData)
      console.log('errosdatamodal.value', oldValue)
    })

    return {
      refErrorModal,
      errorsDataModal

    }
  }
}
</script>

<style lang="scss">
#product-card-error-modal___BV_modal_content_ {
  height: 500px;
}

#product-card-error-modal___BV_modal_body_ {
  overflow: auto;
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>